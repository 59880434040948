<template>
  <DashboardTemplate :sections="sections" :grid-area-component="gridAreaComponentT">
    <template #navbarItems>
      <div class="dashboard-navbar__route">
        {{ $t("dashboard.teaching.title") }}
      </div>
    </template>
  </DashboardTemplate>
</template>
<script>
import DashboardTemplate from '@/components/dashboard/Template.vue';

export default {
  name: 'TeachingView',
  components: { DashboardTemplate },
  metaInfo() {
    return {
      title: `${this.$tc('dashboard.breadcrumbs.app_name')} | ${this.$tc('dashboard.breadcrumbs.teachers')}`,
      meta: [{ name: 'robots', content: 'noindex, nofollow' }],
    };
  },
  data() {
    return {
      sections: [
        {
          name: 'searching-teachers',
          title: 'Preescolar',
          showTitle: false,
          permissions: ['headmaster'],
          gridArea: 'searching-teachers',
        },
        {
          name: 'searching-teachers',
          title: 'Media',
          showTitle: false,
          permissions: ['headmaster'],
          gridArea: 'searching-teachers',
        },
        {
          name: 'searching-teachers',
          title: 'Básica',
          showTitle: false,
          permissions: ['headmaster'],
          gridArea: 'searching-teachers',
        },
      ],
      gridAreaComponentT: '"searching-teachers searching-teachers searching-teachers searching-teachers searching-teachers searching-teachers" "searching-teachers searching-teachers searching-teachers searching-teachers searching-teachers searching-teachers" "searching-teachers searching-teachers searching-teachers searching-teachers searching-teachers searching-teachers" "searching-teachers searching-teachers searching-teachers searching-teachers searching-teachers searching-teachers"',
    };
  },
};
</script>
